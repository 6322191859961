import React from 'react';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

import { $t } from '@17live/app/utils';
import {
  Heading4,
  Heading6,
  TextBody2,
} from '@17live/design-system/Typography/Typography';

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

// TODO: replaces with img gui url lokalises
const InfoImg = styled.div<{ src: string }>`
  width: 100%;
  /* src: ${({ src }) => src}; */
  background-color: #d9d9d9;
  aspect-ratio: 5 / 3;
`;

const AIAssistantIntroduction = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Heading4>{$t('ai_assistant_info_subtitle')}</Heading4>
      <Section>
        <Heading6>{$t('ai_assistant_info_heading_1')}</Heading6>
        <TextBody2>{$t('ai_assistant_info_desc_1')}</TextBody2>
        <InfoImg
          src={intl.formatMessage({
            id: 'ai_assistant_info_img_1',
          })}
        />
      </Section>
      <Section>
        <Heading6>{$t('ai_assistant_info_heading_2')}</Heading6>
        <TextBody2>{$t('ai_assistant_info_desc_2')}</TextBody2>
        <InfoImg
          src={intl.formatMessage({
            id: 'ai_assistant_info_img_2',
          })}
        />
      </Section>
      <Section>
        <Heading6>{$t('ai_assistant_info_heading_3')}</Heading6>
        <TextBody2>{$t('ai_assistant_info_desc_3')}</TextBody2>
        <InfoImg
          src={intl.formatMessage({
            id: 'ai_assistant_info_img_3',
          })}
        />
      </Section>
      <Section>
        <Heading6>{$t('ai_assistant_info_heading_4')}</Heading6>
        <TextBody2>{$t('ai_assistant_info_desc_4')}</TextBody2>
        <InfoImg
          src={intl.formatMessage({
            id: 'ai_assistant_info_img_4',
          })}
        />
      </Section>
    </Wrapper>
  );
};

export default AIAssistantIntroduction;
